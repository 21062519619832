export const BANK_CREDIT_PENDING = 1;
export const BANK_CREDIT_ACCEPTED = 2;
export const BANK_CREDIT_REJECTED = 3;
export const BANK_CREDIT_WAITING = 4;
export const BANK_CREDIT_REGISTERED = 6;
export const BANK_CREDIT_STATUSES = {
  [BANK_CREDIT_PENDING]: 'на рассмотрении',
  [BANK_CREDIT_ACCEPTED]: 'одобрена',
  [BANK_CREDIT_REJECTED]: 'отказано',
  [BANK_CREDIT_WAITING]: 'ожидает действий клиента',
  [BANK_CREDIT_REGISTERED]: 'зарегистрирована',
};
